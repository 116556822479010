<template>
    <v-container fluid>
        <v-card elevation="0" outlined>
            <v-card-title class="headline">
                {{this.$t('settings.lang_createTemplate')}}
            </v-card-title>

            <v-divider class="pa-0 ma-0"/>

            <v-card-text>
                <v-row align="center" align-content="center" justify="center">
                    <v-col cols="12" md="6" sm="10">
                        <v-form lazy-validation ref="form">
                            <b-form-input :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" size="lg" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" :placeholder="this.$t('erp.lang_TemplateName')"
                                          :rules="[ v => !!v]"
                                          outlined v-model="template"/>

                            <br/>
                            <v-btn :disabled="this.loading" :loading="this.loading" @click="create" block class="ma-0"
                                   color="primary" large>{{this.$t('generic.lang_apply')}}
                            </v-btn>
                        </v-form>
                    </v-col>

                    <v-col cols="12">

                        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
                            <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                                :defaultKeySet="touchKeyboard.keySet"
                                                :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                                :options="touchKeyboard.options" class="internalWidthExpanded"
                                                id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import {Events} from "../../../../plugins/events";
    //configs
    import mixin from "../../../../mixins/KeyboardMixIns";
    import {ENDPOINTS} from "../../../../config";

    export default {
        name: "PriceTemplateCreateComponent",
        mixins: [mixin],
        data: () => {
            return {
                ENDPOINTS,
                template: "",
                loading: false
            }
        },
        methods: {
            create() {
                if (!this.$refs.form.validate()) return;
                this.loading = true;
                this.axios.post(ENDPOINTS.ERP.PRICETEMPLATES.CREATE,
                    {
                        priceTMPLName: this.template,
                    }).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            color: "success",
                            message: this.$t('erp.lang_priceTemplAdded')
                        });

                        this.$refs.form.reset();
                        this.$router.go(-1);
                    }
                }).catch((err) => {

                    Events.$emit("showSnackbar", {
                        color: "error",
                        message: err.message
                    });
                }).finally(() => {
                    this.loading = false;
                })

            }
        }
    }
</script>

<style scoped>

</style>